/* eslint-disable sonarjs/no-duplicate-string */
import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';
import * as Pages from 'pages';
import { UserPersmission } from 'utils/userPermission';
import * as Themes from 'styles/theme';

// const CollectionPage = 'Collections';
// const CollectionDetailPage = 'Collections Details';
// const CollectionPageLink = '/collections';

const CD = 'Cashier Dashboard';
export const CashierPagesRoutes = [
	{
		id: 'cashier-1',
		key: 'cashier-1',
		path: '/settlement',
		excludedroute: true,
		element: <Pages.VerificationPage />,
		includedGroups: UserPersmission.verification,
		breadCrumbs: [{ id: 1, text: 'Invoice Verfication Dashboard', link: '' }],
	},
	{
		id: 'cashier-2',
		key: 'cashier-2',
		path: '/settlement/salesman-details',
		excludedroute: true,
		includedGroups: UserPersmission.verification,
		element: <Pages.SalesPerson />,
		breadCrumbs: [
			{ id: 1, text: 'Invoice Verfication Dashboard', link: '/settlement' },
			{ id: 2, text: 'SalesMan Details', link: '' },
		],
	},
	{
		id: 'cashier-2-v2',
		key: 'cashier-2-v2',
		path: '/v2/settlement/salesman-details',
		excludedroute: true,
		includedGroups: UserPersmission.verification,
		element: <Pages.SalesPersonV2 />,
		breadCrumbs: [
			{ id: 1, text: 'Invoice Verfication Dashboard', link: '/settlement' },
			{ id: 2, text: 'SalesMan Details', link: '' },
		],
	},
	{
		id: 'cashier-3',
		key: 'cashier-3',
		path: '/cashier',
		excludedroute: true,
		element: <Pages.CashierDashboard />,
		includedGroups: UserPersmission.collectionSettlement,
		breadCrumbs: [{ id: 1, text: CD, link: '' }],
	},
	{
		id: 'cashier-4',
		key: 'cashier-4',
		excludedroute: true,
		path: '/cashier/salesman-details',
		element: <Pages.InvoicesVerifiedList />,
		includedGroups: UserPersmission.collectionSettlement,
		breadCrumbs: [
			{ id: 1, text: CD, link: '/cashier' },
			{ id: 2, text: 'SalesMan Details', link: '' },
		],
	},
	{
		id: 'cashier-4-v2',
		key: 'cashier-4-v2',
		excludedroute: true,
		path: '/v2/cashier/salesman-details',
		element: <Pages.InvoicesVerifiedListV2 />,
		includedGroups: UserPersmission.collectionSettlement,
		breadCrumbs: [
			{ id: 1, text: CD, link: '/cashier' },
			{ id: 2, text: 'SalesMan Details', link: '' },
		],
	},
	{
		id: 'cashier-5',
		key: 'cashier-5',
		excludedroute: true,
		path: '/cashier/cash-verify',
		element: <Pages.CashVerification />,
		includedGroups: UserPersmission.bankSettlement,
		breadCrumbs: [
			{ id: 1, text: CD, link: '/cashier' },
			{ id: 2, text: 'Cash Verification', link: '' },
		],
	},
	{
		id: 'cashier-5-v2',
		key: 'cashier-5-v2',
		excludedroute: true,
		path: '/cashier/payment-verify',
		element: <Pages.PaymentVerification />,
		includedGroups: UserPersmission.bankSettlement,
		breadCrumbs: [
			{ id: 1, text: CD, link: '/cashier' },
			{ id: 2, text: 'Verification', link: '' },
		],
	},
	{
		id: 'cashier-6',
		key: 'cashier-6',
		excludedroute: true,
		path: '/cashier/cheque-verify',
		element: <Pages.ChequeVerification />,
		includedGroups: UserPersmission.bankSettlement,
		breadCrumbs: [
			{ id: 1, text: CD, link: '/cashier' },
			{ id: 2, text: 'Cheque Verification', link: '' },
		],
	},
	{
		id: 'cashier-7',
		key: 'cashier-7',
		excludedroute: true,
		path: '/cashier/upi-verify',
		element: <Pages.UpiVerification />,
		includedGroups: UserPersmission.bankSettlement,
		breadCrumbs: [
			{ id: 1, text: CD, link: '/cashier' },
			{ id: 2, text: 'UPI Verification', link: '' },
		],
	},
	{
		id: 'cashier-71',
		key: 'cashier-71',
		excludedroute: true,
		path: '/cashier/neft-verify',
		element: <Pages.NEFTVerification />,
		includedGroups: UserPersmission.bankSettlement,
		breadCrumbs: [
			{ id: 1, text: CD, link: '/cashier' },
			{ id: 2, text: 'NEFT', link: '' },
		],
	},
	{
		id: 'cashier-8',
		excludedroute: true,
		key: 'cashier-8',
		path: '/bank-settlement/history',
		element: <Pages.BankSettlementPage />,
		includedGroups: UserPersmission.bankSettlement,
		breadCrumbs: [{ id: 1, text: 'Bank Settlements', link: '' }],
	},
	// {
	// 	id: 'cashier-10',
	// 	key: 'cashier-10',
	// 	excludedroute: true,
	// 	path: '/additional-settlement',
	// 	includedGroups: UserPersmission.additionalSettlement,
	// 	element: <Pages.AdditionalSettlementList />,
	// 	breadCrumbs: [{ id: 1, text: 'Additional Payment', link: '' }],
	// },
	{
		id: 'cashier-11',
		key: 'cashier-11',
		path: '/allocation',
		excludedroute: true,
		element: <Pages.NextDayCollectionListPage />,
		includedGroups: UserPersmission.allocation,
		breadCrumbs: [{ id: 1, text: 'Invoice Allocation', link: '' }],
	},
	{
		id: 'cashier-11a',
		key: 'cashier-11a',
		path: '/allocation/salesman-allocation-details',
		excludedroute: true,
		element: <Pages.SalesmanAllocationDetail />,
		includedGroups: UserPersmission.allocation,
		breadCrumbs: [
			{ id: 1, text: 'allocation', link: '/allocation' },
			{ id: 2, text: 'salesman-allocation-details', link: '' },
		],
	},
	{
		id: 'cashier-12',
		key: 'cashier-12',
		path: '/bank-settlement/cheque-settlements',
		excludedroute: true,
		element: <Pages.ChequeBanksettlementReg />,
		includedGroups: UserPersmission.bankSettlement,
		breadCrumbs: [
			{ id: 1, text: 'Bank Settlement', link: '/bank-settlement/history' },
			{ id: 2, text: 'Cheque Settlement', link: '' },
		],
	},
];

export const GlobalStyle = createGlobalStyle`
	${reset}
	html {
		background-color: #F4F6F8;
		margin: 0;
		font-family: 'Open Sans', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	body { 
    margin: 0 auto;
	width: 100%;
	background-color: #F4F6F8;
	min-height: 100vh;
	}
`;

export const getThemes = (val) => {
	switch (val) {
		case 'oldtheme':
			return Themes.oldTheme;
		case 'orangetheme':
			return Themes.orangeTheme;
		default:
			return Themes.oldTheme;
	}
};

export const unAuthPages = [
	{ id: 1, key: '1', path: '/', element: <Pages.Login /> },
	{ id: 2, key: '2', path: '/login', element: <Pages.Login /> },
	{ id: 3, key: '3', path: '/forgot-password', element: <Pages.ForgotPassword /> },
	{ id: 4, key: '4', path: '/reset-password', element: <Pages.ResetPassword /> },
];

/**
 * In private page, we have included breadcrumds
 * to manage it in one place
 */

export const privatePages = (state) => [
	{
		id: 1,
		key: '1',
		path: '/dashboard',
		element: <Pages.Dashboard />,
		includedGroups: UserPersmission.dashboard,
		breadCrumbs: [{ id: 1, text: 'Dashboard', link: '' }],
	},
	{
		id: 2,
		key: '2',
		path: '/uploads',
		element: <Pages.UploadListPage />,
		includedGroups: UserPersmission.upload_list,
		breadCrumbs: [{ id: 1, text: 'Uploads', link: '' }],
	},
	{
		id: 27,
		key: '27',
		path: '/adapter-uploads',
		element: <Pages.UploadListPageNew />,
		includedGroups: UserPersmission.upload_list,
		breadCrumbs: [{ id: 1, text: 'Adapter Uploads', link: '' }],
	},
	{
		id: 22,
		key: '22',
		path: '/uploads/:id',
		element: <Pages.UploadShowPage />,
		includedGroups: UserPersmission.upload_show,
		breadCrumbs: [
			{ id: 1, text: 'Uploads', link: '/uploads' },
			{ id: 2, text: 'Uploads View', link: '' },
		],
	},
	{
		id: 225,
		key: '225',
		path: '/adapter-uploads/:id',
		element: <Pages.UploadShowPageNew />,
		includedGroups: UserPersmission.upload_show,
		breadCrumbs: [
			{ id: 1, text: 'Adapter Uploads', link: '/adapter-uploads' },
			{ id: 2, text: 'Adapter Uploads View', link: '' },
		],
	},
	{
		id: 2251,
		key: '2251',
		path: '/adapter-uploads-view/:id',
		element: <Pages.SalesReturnUploadView />,
		includedGroups: UserPersmission.upload_show,
		breadCrumbs: [
			{ id: 1, text: 'Adapter Uploads', link: '/adapter-uploads' },
			{ id: 2, text: 'Adapter Uploads View', link: '' },
		],
	},
	{
		id: 3,
		key: '3',
		path: '/downloads',
		element: <Pages.Downloads />,
		includedGroups: UserPersmission.downloads,
		breadCrumbs: [{ id: 1, text: 'Downloads', link: '' }],
	},

	// {
	// 	id: 4,
	// 	key: '4',
	// 	path: '/warehouse-management/grn',
	// 	element: <Pages.GRN />,
	// 	includedGroups: UserPersmission.grn_list,
	// 	breadCrumbs: [
	// 		{ id: 3, text: 'Warehouse Management', link: '/warehouse-management' },
	// 		{ id: 1, text: 'GRN', link: '' },
	// 	],
	// },
	{
		id: 333,
		key: '333',
		path: '/warehouse-management/asn/grn',
		element: <Pages.GRN />,
		includedGroups: UserPersmission.grn_list,
		breadCrumbs: [
			{ id: 3, text: 'Warehouse Management', link: '/warehouse-management' },
			{ id: 2, text: 'ASN', link: '/warehouse-management/asn' },
			{ id: 1, text: 'GRN', link: '' },
		],
	},

	// inventory
	{
		id: 92,
		key: '92',
		path: '/warehouse-management/inventory',
		element: <Pages.Inventory />,
		includedGroups: UserPersmission.dashboard,
		breadCrumbs: [
			{ id: 3, text: 'Warehouse Management', link: '/warehouse-management' },
			{ id: 1, text: 'Inventory', link: '/warehouse-management/inventory' },
		],
	},
	{
		id: 5,
		key: '5',
		path: '/warehouse-management/inventory/current-inventory',
		element: <Pages.CurrentInventory />,
		includedGroups: UserPersmission.inventory_currentInventory,
		breadCrumbs: [
			{ id: 3, text: 'Warehouse Management', link: '/warehouse-management' },
			{ id: 1, text: 'Inventory', link: '/warehouse-management/inventory' },
			{ id: 2, text: 'Current Inventory', link: '' },
		],
	},
	{
		id: 6,
		key: '6',
		path: '/warehouse-management/inventory/unsaleable-inventory',
		element: <Pages.UnsaleableInventory />,
		includedGroups: UserPersmission.inventory_unsaleableInventory,
		breadCrumbs: [
			{ id: 3, text: 'Warehouse Management', link: '/warehouse-management' },
			{ id: 1, text: 'Inventory', link: '/warehouse-management/inventory' },
			{ id: 2, text: 'Unsaleable Inventory', link: '' },
		],
	},
	// Finance Management
	{
		id: 123,
		key: '123',
		path: '/finance-management',
		element: <Pages.FinanceManagementDashBoardPage />,
		includedGroups: UserPersmission.transactionLedger_list,
		breadCrumbs: [{ id: 1, text: 'Finance Management', link: '/finance-management' }],
	},
	{
		id: 13,
		key: '13',
		path: '/finance-management/retailer-ledger',
		element: <Pages.TransactionLedger />,
		includedGroups: UserPersmission.transactionLedger_list,
		breadCrumbs: [
			{ id: 1, text: 'Finance Management', link: '/finance-management' },
			{ id: 2, text: 'Retailer Ledger', link: '' },
		],
	},
	// On Boarding
	{
		id: 81,
		key: '81',
		path: '/onboarding',
		element: <Pages.Onboarding />,
		includedGroups: UserPersmission.dashboard,
		breadCrumbs: [{ id: 1, text: 'On Boarding', link: '' }],
	},
	{
		id: 1200,
		key: '1200',
		path: '/onboarding/store',
		element: <Pages.StoreListPage />,
		includedGroups: UserPersmission.store_list,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Store', link: '' },
		],
	},
	{
		id: 1200,
		key: '1200',
		path: '/onboarding/store/create',
		element: <Pages.StoreCreateEditPage />,
		includedGroups: UserPersmission.store_create,
		breadCrumbs: [
			{ id: 1, text: 'Onboarding', link: '/onboarding' },
			{ id: 2, text: 'Store', link: '/onboarding/store' },
			{ id: 3, text: 'Create Store', link: '' },
		],
	},
	{
		id: 1210,
		key: '1210',
		path: '/onboarding/store/:id/edit',
		element: <Pages.StoreCreateEditPage />,
		includedGroups: UserPersmission.store_create,
		breadCrumbs: [
			{ id: 1, text: 'Onboarding', link: '/onboarding' },
			{ id: 2, text: 'Store', link: '/onboarding/store' },
			{ id: 3, text: 'Edit Store', link: '' },
		],
	},
	{
		id: 7,
		key: '7',
		path: '/onboarding/fc',
		element: <Pages.Fc />,
		includedGroups: UserPersmission.fc_list,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Fulfillment Centers', link: '' },
		],
	},
	{
		id: 8,
		key: '8',
		path: '/onboarding/fc/add',
		element: <Pages.AddFc />,
		includedGroups: UserPersmission.fc_create,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Fulfillment Centers', link: '/onboarding/fc' },
			{ id: 3, text: 'Add Fulfillment Centers', link: '' },
		],
	},
	{
		id: 2,
		key: '23',
		path: '/onboarding/fc/:id/edit',
		element: <Pages.AddFc />,
		includedGroups: UserPersmission.fc_create,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Fulfillment Centers', link: '/onboarding/fc' },
			{ id: 3, text: 'Edit Fulfillment Centers', link: '' },
		],
	},
	{
		id: 11,
		key: '11',
		path: '/onboarding/brand',
		element: <Pages.Brand />,
		includedGroups: UserPersmission.brand_list,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Brand', link: '' },
		],
	},
	{
		id: 12,
		key: '12',
		path: '/onboarding/brand/add',
		element: <Pages.AddBrand />,
		includedGroups: UserPersmission.brand_create,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding/' },
			{ id: 2, text: 'Brand', link: '/onboarding/brand' },
			{ id: 3, text: 'Add Brand', link: '' },
		],
	},
	{
		id: 12,
		key: '12',
		path: '/onboarding/brand/:id/edit',
		element: <Pages.AddBrand />,
		includedGroups: UserPersmission.brand_create,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding/' },
			{ id: 2, text: 'Brand', link: '/onboarding/brand' },
			{ id: 3, text: 'Edit Brand', link: '' },
		],
	},
	{
		id: 22,
		key: '22',
		path: '/onboarding/user',
		element: <Pages.User />,
		includedGroups: UserPersmission.user_list,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'User', link: '' },
		],
	},
	{
		id: 23,
		key: '23',
		path: '/onboarding/user/add',
		element: <Pages.AddUser />,
		includedGroups: UserPersmission.user_create,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'User', link: '/onboarding/user' },
			{ id: 3, text: 'Add User', link: '' },
		],
	},
	{
		id: 213,
		key: '213',
		path: '/onboarding/user/:id/edit',
		element: <Pages.AddUser />,
		includedGroups: UserPersmission.user_create,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'User', link: '/onboarding/user' },
			{ id: 3, text: 'Edit User', link: '' },
		],
	},
	{
		id: 46,
		key: '46',
		path: '/onboarding/salesman',
		element: <Pages.Salesman />,
		includedGroups: UserPersmission.salesman_list,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Salesman', link: '' },
		],
	},
	{
		id: 47,
		key: '47',
		path: '/onboarding/salesman/add',
		element: <Pages.AddSalesman />,
		includedGroups: UserPersmission.user_create,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Salesman', link: '/onboarding/salesman' },
			{ id: 3, text: 'Add Salesman', link: '' },
		],
	},
	{
		id: 48,
		key: '48',
		path: '/onboarding/salesman/:id/edit',
		element: <Pages.AddSalesman />,
		includedGroups: UserPersmission.user_create,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Salesman', link: '/onboarding/salesman' },
			{ id: 3, text: 'Edit Salesman', link: '' },
		],
	},
	{
		id: 230,
		key: '230',
		path: '/onboarding/company',
		element: <Pages.CompanyListPage />,
		includedGroups: UserPersmission.company_list,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Company', link: '' },
		],
	},
	{
		id: 2390,
		key: '2390',
		path: '/onboarding/company/add',
		element: <Pages.CompanyEditCreatePage />,
		includedGroups: UserPersmission.company_create,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Company', link: '/onboarding/company' },
			{ id: 3, text: 'Add Company', link: '' },
		],
	},
	{
		id: 2391,
		key: '2391',
		path: '/onboarding/company/:id/edit',
		element: <Pages.CompanyEditCreatePage />,
		includedGroups: UserPersmission.company_create,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Company', link: '/onboarding/company' },
			{ id: 3, text: 'Edit Company', link: '' },
		],
	},
	{
		id: 231,
		key: '231',
		path: '/onboarding/client',
		element: <Pages.ClientListPage />,
		includedGroups: UserPersmission.client_list,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Client', link: '' },
		],
	},
	{
		id: 2333,
		key: '2333',
		path: '/onboarding/client/add',
		element: <Pages.ClientEditCreatePage />,
		includedGroups: UserPersmission.client_create,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Client', link: '/onboarding/client' },
			{ id: 3, text: 'Add Client', link: '' },
		],
	},
	{
		id: 2366,
		key: '2366',
		path: '/onboarding/client/:id/edit',
		element: <Pages.ClientEditCreatePage />,
		includedGroups: UserPersmission.client_create,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Client', link: '/onboarding/client' },
			{ id: 3, text: 'Edit Client', link: '' },
		],
	},
	// Masters
	{
		id: 91,
		key: '91',
		path: '/onboarding/masters',
		element: <Pages.Masters />,
		includedGroups: UserPersmission.dashboard,
		breadCrumbs: [
			{ id: 3, text: 'On Boarding', link: '/onboarding' },
			{ id: 1, text: 'Masters', link: '/onboarding/masters' },
		],
	},
	{
		id: 9,
		key: '9',
		path: '/onboarding/masters/bank',
		element: <Pages.Bank />,
		includedGroups: UserPersmission.bank_list,
		breadCrumbs: [
			{ id: 3, text: 'On Boarding', link: '/onboarding' },
			{ id: 1, text: 'Masters', link: '/onboarding/masters' },
			{ id: 2, text: 'Bank', link: '' },
		],
	},
	{
		id: 923,
		key: '923',
		path: '/onboarding/masters/pincode',
		element: <Pages.PinCodePage />,
		includedGroups: UserPersmission.pincode_list,
		breadCrumbs: [
			{ id: 3, text: 'On Boarding', link: '/onboarding' },
			{ id: 1, text: 'Masters', link: '/onboarding/masters' },
			{ id: 2, text: 'Pincode', link: '' },
		],
	},
	{
		id: 101,
		key: '101',
		path: '/onboarding/masters/packmaster',
		element: <Pages.PackMaster />,
		includedGroups: UserPersmission.upload_list,
		breadCrumbs: [
			{ id: 3, text: 'On Boarding', link: '/onboarding' },
			{ id: 1, text: 'Masters', link: '/onboarding/masters' },
			{ id: 2, text: 'Pack Master', link: '' },
		],
	},

	{
		id: 10,
		key: '10',
		path: '/onboarding/masters/bank/add',
		element: <Pages.AddBank />,
		includedGroups: UserPersmission.bank_create,
		breadCrumbs: [
			{ id: 3, text: 'On Boarding', link: '/onboarding' },
			{ id: 1, text: 'Masters', link: '/onboarding/masters' },
			{ id: 2, text: 'Bank', link: '/onboarding/masters/bank' },
			{ id: 3, text: 'Add Bank', link: '' },
		],
	},
	{
		id: 102,
		key: '102',
		path: '/onboarding/masters/packmaster/add',
		element: <Pages.CreateEditPackMaster />,
		includedGroups: UserPersmission.packmaster_create,
		breadCrumbs: [
			{ id: 3, text: 'On Boarding', link: '/onboarding' },
			{ id: 1, text: 'Masters', link: '/onboarding/masters' },
			{ id: 2, text: 'Pack Master', link: '/onboarding/masters/packmaster' },
			{ id: 4, text: 'Add Pack Master', link: '' },
		],
	},
	{
		id: 1220,
		key: '1220',
		path: '/onboarding/retail-category',
		element: <Pages.CategoryPage />,
		includedGroups: UserPersmission.storeCategory_list,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Store Category', link: '' },
		],
	},
	{
		id: 1221,
		key: '1221',
		path: '/onboarding/retail-category/create',
		element: <Pages.CategoryEditCreate />,
		includedGroups: UserPersmission.storeCategory_create,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Store Category', link: '/onboarding/retail-category' },
			{ id: 2, text: 'Create Category', link: '' },
		],
	},
	{
		id: 1222,
		key: '1222',
		path: '/onboarding/retail-category/:id/edit',
		element: <Pages.CategoryEditCreate />,
		includedGroups: UserPersmission.storeCategory_create,
		breadCrumbs: [
			{ id: 1, text: 'On Boarding', link: '/onboarding' },
			{ id: 2, text: 'Store Category', link: '/onboarding/retail-category' },
			{ id: 2, text: 'Edit Store Category', link: '' },
		],
	},
	{
		id: 103,
		key: '103',
		path: '/onboarding/masters/packmaster/:id/edit',
		element: <Pages.CreateEditPackMaster />,
		includedGroups: UserPersmission.packmaster_create,
		breadCrumbs: [
			{ id: 3, text: 'On Boarding', link: '/onboarding' },
			{ id: 1, text: 'Masters', link: '/onboarding/masters' },
			{ id: 2, text: 'Pack Master', link: '/onboarding/masters/packmaster' },
			{ id: 3, text: 'Edit Pack Master', link: '' },
		],
	},
	// Fulfilment
	// {
	// 	id: 93,
	// 	key: '93',
	// 	path: '/fulfillment',
	// 	element: <Pages.Fulfillment />,
	// 	includedGroups: UserPersmission.dashboard,
	// 	breadCrumbs: [{ id: 1, text: 'Fulfillment', link: '/fulfillment' }],
	// },
	{
		id: 165,
		key: '165',
		path: '/order-management',
		element: <Pages.OrderManagementDashBoardPage />,
		includedGroups: UserPersmission.deliveryAllocation_list,
		breadCrumbs: [{ id: 1, text: 'Order Management', link: '/order-management' }],
	},
	{
		id: 13,
		key: '13',
		path: '/order-management/sales-order',
		element: <Pages.SalesOrder />,
		includedGroups: UserPersmission.salesOrder_list,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Sales Order', link: '' },
		],
	},
	{
		id: 18,
		key: '18',
		path: '/order-management/sales-order/:id',
		element: <Pages.SalesOrderView />,
		includedGroups: UserPersmission.salesOrder_show,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Sales Order', link: '/order-management/sales-order' },
			{ id: 3, text: 'View', link: '' },
		],
	},
	{
		id: 1818,
		key: '1818',
		path: '/order-management/sales-order/mark-eco-bills',
		element: <Pages.MarkEcoBills />,
		includedGroups: UserPersmission.salesOrder_show,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Sales Order', link: '/order-management/sales-order' },
			{ id: 3, text: 'Mark ECO Bills', link: '' },
		],
	},
	{
		id: 8181,
		key: '8181',
		path: '/order-management/sales-order/mark-pod-bills',
		element: <Pages.MarkPodBills />,
		includedGroups: UserPersmission.salesOrder_show,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Sales Order', link: '/order-management/sales-order' },
			{ id: 3, text: 'Mark Pay On Delivery', link: '' },
		],
	},
	{
		id: 8182,
		key: '8182',
		path: '/order-management/sales-order/blocked-order',
		element: <Pages.BlockedOrder />,
		includedGroups: UserPersmission.salesOrder_show,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Sales Order', link: '/order-management/sales-order' },
			{ id: 3, text: 'Blocked Order', link: '' },
		],
	},
	{
		id: 26,
		key: '26',
		path: '/order-management/sales-order/management/:id',
		element: <Pages.SalesOrderDetails />,
		includedGroups: UserPersmission.salesOrder_show,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Sales Order', link: '/order-management/sales-order' },
			{ id: 3, text: 'Sales Return', link: '' },
		],
	},
	{
		id: 331,
		key: '331',
		path: '/order-management/returns',
		element: <Pages.ReturnsLogs />,
		includedGroups: UserPersmission.salesReturn_list,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Returns', link: '' },
		],
	},
	{
		id: 332,
		key: '332',
		path: '/order-management/brand-sales-returns',
		element: <Pages.BrandSalesReturn />,
		includedGroups: UserPersmission.salesReturn_list,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Brand Sales Returns', link: '' },
		],
	},
	{
		id: 975,
		key: '975',
		path: '/order-management/returns/search-sales-return',
		element: <Pages.SearchSalesReturn />,
		includedGroups: UserPersmission.salesReturn_list,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Returns', link: '/order-management/returns' },
			{ id: 3, text: 'Search Sales Returns', link: '' },
		],
	},
	{
		id: 973,
		key: '973',
		path: '/order-management/returns/search-sales-return/:id',
		element: <Pages.AddSalesReturn />,
		includedGroups: UserPersmission.salesReturn_list,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Returns', link: '/order-management/returns' },
			{
				id: 3,
				text: 'Search Sales Returns',
				link: '/order-management/returns/search-sales-return',
			},
			{ id: 4, text: 'Add Sales Returns', link: '' },
		],
	},
	{
		id: 973,
		key: '973',
		path: '/order-management/returns/update-sales-return/:id',
		element: <Pages.UpdateSalesReturn />,
		includedGroups: UserPersmission.salesReturn_list,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Returns', link: '/order-management/returns' },
			// {
			// 	id: 3,
			// 	text: '',
			// 	link: '/order-management/returns/update-sales-return',
			// },
			{ id: 4, text: 'Update Sales Returns', link: '' },
		],
	},
	{
		id: 99,
		key: '99',
		path: '/order-management/returns/:id',
		element: <Pages.ReturnsLogsShow />,
		includedGroups: UserPersmission.salesOrder_show,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Returns', link: '/order-management/returns' },
			{ id: 3, text: 'View', link: '' },
		],
	},
	{
		id: 145,
		key: '145',
		path: '/logistics-management',
		element: <Pages.LogisticsMovementDashBoardPage />,
		includedGroups: UserPersmission.deliveryAllocation_list,
		breadCrumbs: [{ id: 1, text: 'Logistics Management', link: '/logistics-management' }],
	},
	{
		id: 14,
		key: '14',
		path: '/logistics-management/delivery-allocation',
		element: <Pages.DeliveryAllocation />,
		includedGroups: UserPersmission.deliveryAllocation_list,
		breadCrumbs: [
			{ id: 1, text: 'Logistics Management', link: '/logistics-management' },
			{ id: 2, text: 'Delivery Allocation', link: '' },
		],
	},
	{
		id: 20,
		key: '20',
		path: '/logistics-management/delivery-allocation/:id/show',
		element: <Pages.DeliveryAllocationShow />,
		includedGroups: UserPersmission.deliveryAllocation_show,
		breadCrumbs: [
			{ id: 1, text: 'Logistics Management', link: '/logistics-management' },
			{
				id: 2,
				text: 'Delivery Allocation',
				link: '/logistics-management/delivery-allocation',
			},
			{ id: 3, text: 'Delivery Allocation View', link: '' },
		],
	},
	// {
	// 	id: 15,
	// 	key: '15',
	// 	path: '/logistics-management/return-to-fc',
	// 	element: <Pages.ReturnToFC />,
	// 	includedGroups: UserPersmission.returnToFC_details,
	// 	breadCrumbs: [
	// 		{ id: 1, text: 'Logistics Management', link: '/logistics-management' },
	// 		{ id: 2, text: 'Return To FC', link: '' },
	// 	],
	// },
	{
		id: 16,
		key: '16',
		path: '/order-management/sales-return',
		element: <Pages.SalesReturn />,
		includedGroups: UserPersmission.salesReturn_list,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Sales Return', link: '' },
		],
	},
	{
		id: 17,
		key: '17',
		path: '/profile',
		element: <Pages.UserProfile />,
		includedGroups: UserPersmission.dashboard,
		breadCrumbs: [{ id: 1, text: 'Profile', link: '' }],
	},
	{
		id: 137,
		key: '137',
		path: '/warehouse-management',
		element: <Pages.WareHouseManagementPage />,
		includedGroups: UserPersmission.dashboard,
		breadCrumbs: [{ id: 3, text: 'Warehouse Management', link: '' }],
	},
	{
		id: 1379,
		key: '1379',
		path: '/warehouse-management/asn',
		element: <Pages.WareHouseManagementPage />,
		includedGroups: UserPersmission.dashboard,
		breadCrumbs: [
			{ id: 3, text: 'Warehouse Management', link: '/warehouse-management' },
			{ id: 1, text: 'ASN', link: '' },
		],
	},
	// {
	// 	id: 18,
	// 	key: '18',
	// 	path: '/warehouse-management/grn/:id/show',
	// 	element: <Pages.GRNShow />,
	// 	includedGroups: UserPersmission.grn_show,
	// 	breadCrumbs: [
	// 		{ id: 3, text: 'Warehouse Management', link: '/warehouse-management' },
	// 		{ id: 1, text: 'GRN', link: '/warehouse-management/grn' },
	// 		{ id: 2, text: 'GRN View', link: '' },
	// 	],
	// },
	{
		id: 69,
		key: '69',
		path: '/warehouse-management/asn/grn/:id/show',
		element: <Pages.GRNShow />,
		includedGroups: UserPersmission.grn_show,
		breadCrumbs: [
			{ id: 3, text: 'ASN', link: '/warehouse-management/asn' },
			{ id: 1, text: 'GRN', link: '/warehouse-management/asn/grn' },
			{ id: 2, text: 'GRN View', link: '' },
		],
	},
	{
		id: 19,
		key: '19',
		path: '/order-management/sales-return/:id/show',
		element: <Pages.SalesReturnShow />,
		includedGroups: UserPersmission.salesReturn_show,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Sales Return', link: '/order-management/sales-return' },
			{ id: 3, text: 'Sales Return View', link: '' },
		],
	},
	{
		id: 'brand-sales-return',
		key: 'brand-sales-return',
		path: '/order-management/brand-sales-return/:id/show',
		element: <Pages.BrandSalesReturnShow />,
		includedGroups: UserPersmission.salesReturn_show,
		breadCrumbs: [
			{ id: 1, text: 'Order Management', link: '/order-management' },
			{ id: 2, text: 'Brand Sales Return', link: '/order-management/brand-sales-returns' },
			{ id: 3, text: 'Brand Sales Return View', link: '' },
		],
	},
	// {
	// 	id: 20,
	// 	key: '20',
	// 	path: '/logistics-management/return-to-fc/:id/show',
	// 	element: <Pages.ReturnToFCShow />,
	// 	includedGroups: UserPersmission.returnToFC_details,
	// 	breadCrumbs: [
	// 		{ id: 1, text: 'Logistics Management', link: '/logistics-management' },
	// 		{ id: 2, text: 'Return To FC', link: '/logistics-management/return-to-fc' },
	// 		{ id: 3, text: 'Return To FC View', link: '' },
	// 	],
	// },
	{
		id: 22,
		key: '22',
		path: '/logistics-management/delivery-allocation/create',
		element: <Pages.DeliveryAllocationCreate />,
		includedGroups: UserPersmission.deliveryAllocation_create,
		breadCrumbs: [
			{ id: 1, text: 'Logistics Management', link: '/logistics-management' },
			{
				id: 2,
				text: 'Delivery Allocation',
				link: '/logistics-management/delivery-allocation',
			},
			{ id: 3, text: 'Create Delivery Allocation', link: '' },
		],
	},
	{
		id: 233,
		key: '233',
		path: '/logistics-management/delivery-allocation/missing-invoices',
		element: <Pages.MissingInvoices />,
		includedGroups: UserPersmission.deliveryAllocation_create,
		breadCrumbs: [
			{ id: 1, text: 'Logistics Management', link: '/logistics-management' },
			{
				id: 2,
				text: 'Delivery Allocation',
				link: '/logistics-management/delivery-allocation/create',
			},
			{ id: 3, text: 'View Missing Invoices', link: '' },
		],
	},
	// {
	// 	id: 21,
	// 	key: '21',
	// 	path: '/logistics-management/return-to-fc/:id/return-product',
	// 	element: <Pages.ReturnProduct />,
	// 	includedGroups: UserPersmission.returnToFC_details,
	// 	breadCrumbs: [],
	// },
	// {
	// 	id: 22,
	// 	key: '22',
	// 	path: '/logistics-management/return-to-fc/:id/collection',
	// 	element: <Pages.ReturnCollection />,
	// 	includedGroups: UserPersmission.returnToFC_details,
	// 	breadCrumbs: [
	// 		{ id: 1, text: 'Logistics Management', link: '/logistics-management' },
	// 		{ id: 2, text: 'Return To FC', link: '/logistics-management/return-to-fc' },
	// 		{ id: 3, text: 'Return To FC Collection', link: '' },
	// 	],
	// },
	{
		id: 151,
		key: '115',
		path: '/logistics-management/return-to-fc-new',
		element: <Pages.ReturnToFCPage />,
		includedGroups: UserPersmission.returnToFC_details,
		breadCrumbs: [
			{ id: 1, text: 'Logistics Management', link: '/logistics-management' },
			{ id: 2, text: 'Return To FC', link: '' },
		],
	},
	{
		id: 20,
		key: '20',
		path: `/logistics-management/return-to-fc-new/:id/show`,
		element: <Pages.RTFInvoiceList />,
		includedGroups: UserPersmission.returnToFC_details,
		breadCrumbs: [
			{ id: 1, text: 'Logistics Management', link: '/logistics-management' },
			{ id: 2, text: 'Return To FC', link: '/logistics-management/return-to-fc-new' },
			{ id: 3, text: 'Invoice List', link: '' },
		],
	},
	// {
	// 	id: 21,
	// 	key: '21',
	// 	path: `/logistics-management/return-to-fc-new/:id/detials`,
	// 	element: <Pages.RTCDetailPage />,
	// 	includedGroups: UserPersmission.returnToFC_details,
	// 	breadCrumbs: [
	// 		{ id: 1, text: 'Logistics Management', link: '/logistics-management' },
	// 		{ id: 2, text: 'Return To FC', link: '/logistics-management/return-to-fc-new' },
	// 		{ id: 3, text: 'Return To FC - Details', link: '' },
	// 	],
	// },
	{
		id: `21-new-fc`,
		key: `21-new-fc`,
		path: `/logistics-management/return-to-fc-new/:id/delivery-details`,
		element: <Pages.RTCDeliveryPage />,
		includedGroups: UserPersmission.returnToFC_details,
		breadCrumbs: [
			{ id: 1, text: 'Logistics Management', link: '/logistics-management' },
			{ id: 2, text: 'Return To FC', link: '/logistics-management/return-to-fc-new' },
			{
				id: 4,
				text: 'Invoice List',
				link: state?.returnToFC?.invoiceId
					? `/logistics-management/return-to-fc-new/${state?.returnToFC?.invoiceId}/show`
					: '/logistics-management/return-to-fc-new',
			},
			{ id: 3, text: 'Return To FC - Details', link: '' },
		],
	},
	{
		id: `22-new-fc`,
		key: `22-new-fc`,
		path: `/logistics-management/return-to-fc-new/:id/collection`,
		element: <Pages.RTCCollectionPage />,
		includedGroups: UserPersmission.returnToFC_details,
		breadCrumbs: [
			{ id: 1, text: 'Logistics Management', link: '/logistics-management' },
			{ id: 2, text: 'Return To FC', link: '/logistics-management/return-to-fc-new' },
			{
				id: 4,
				text: 'Invoice List',
				link: state?.returnToFC?.invoiceId
					? `/logistics-management/return-to-fc-new/${state?.returnToFC?.invoiceId}/show`
					: '/logistics-management/return-to-fc-new',
			},
			{ id: 3, text: 'Return To FC - Details', link: '' },
		],
	},
	// {
	// 	id: 22,
	// 	key: '22',
	// 	path: `/logistics-management/return-to-fc/:id/collection`,
	// 	element: <Pages.ReturnCollection />,
	// 	includedGroups: UserPersmission.returnToFC_details,
	// 	breadCrumbs: [
	// 		{ id: 1, text: 'Fulfillment', link: '/fulfillment' },
	// 		{ id: 2, text: 'Return To FC', link: '/logistics-management/return-to-fc-new' },
	// 		{ id: 3, text: 'Return To FC Collection', link: '' },
	// 	],
	// },
	{
		id: 22,
		key: '22',
		path: '/adapter-uploads',
		element: <Pages.OrderAdapterPage />,
		includedGroups: UserPersmission.orderAdapter,
		breadCrumbs: [{ id: 1, text: 'Adapter Uploads', link: '' }],
	},
	// {
	// 	id: 45,
	// 	key: '45',
	// 	path: CollectionPageLink,
	// 	element: <Pages.CollectionListPage />,
	// 	includedGroups: UserPersmission.collections_list,
	// 	breadCrumbs: [{ id: 1, text: CollectionPage, link: '' }],
	// },
	// {
	// 	id: 451,
	// 	key: '451',
	// 	path: `${CollectionPageLink}/:id/details`,
	// 	element: <Pages.CollectionDetailPage />,
	// 	includedGroups: UserPersmission.collections_list,
	// 	breadCrumbs: [
	// 		{ id: 1, text: CollectionPage, link: CollectionPageLink },
	// 		{ id: 2, text: CollectionDetailPage, link: '' },
	// 	],
	// },
	{
		id: 999,
		key: '999',
		path: '/warehouse-management/wms-logs',
		element: <Pages.WmsLogs />,
		includedGroups: UserPersmission.wmslogs_list,
		breadCrumbs: [
			{ id: 1, text: 'Warehouse Management', link: '/warehouse-management' },
			{ id: 2, text: 'WMS Logs', link: '' },
		],
	},
	{
		id: 189,
		key: '189',
		path: '/warehouse-management/po-logs',
		element: <Pages.GrnLogs />,
		includedGroups: UserPersmission.wmslogs_list,
		breadCrumbs: [
			{ id: 1, text: 'Warehouse Management', link: '/warehouse-management' },
			{ id: 2, text: 'PO Logs', link: '' },
		],
	},
	{
		id: 'cheque-bounce-1',
		key: 'cheque-bounce-1',
		path: '/cheque-bounce/entry-list',
		// eslint-disable-next-line import/namespace
		element: <Pages.ChequeBounceData />,
		includedGroups: UserPersmission.chequeBounce,
		breadCrumbs: [{ id: 1, text: 'Cheque Bounce', link: '' }],
	},
	{
		id: 'cheque-bounce-2',
		key: 'cheque-bounce-2',
		path: '/cheque-bounce/cheque-bounce-form',
		// eslint-disable-next-line import/namespace
		element: <Pages.BouncedChequeForm />,
		includedGroups: UserPersmission.chequeBounce,
		breadCrumbs: [{ id: 1, text: 'Add Cheque Bounce', link: '' }],
	},
	{
		id: 'cheque-bounce-3',
		key: 'cheque-bounce-3',
		path: '/cheque-bounce/verification-list',
		// eslint-disable-next-line import/namespace
		element: <Pages.BouncedChequeUpdate />,
		includedGroups: UserPersmission.checkBounceCashier,
		breadCrumbs: [{ id: 1, text: 'Cheque Bounce Verification', link: '' }],
	},
	{
		id: 'downloads_new',
		key: 'downloads_new',
		path: '/new_downloads',
		element: <Pages.DownloadsNew />,
		includedGroups: UserPersmission.downloads,
		breadCrumbs: [{ id: 1, text: 'Downloads', link: '' }],
	},
	...CashierPagesRoutes,
	{
		id: 'pending-collection-view',
		key: 'pending-collection-view',
		path: '/logistics-management/delivery-allocation/pending-collections',
		// eslint-disable-next-line import/namespace
		element: <Pages.PendingCollectionView />,
		includedGroups: UserPersmission.deliveryAllocation_list,
		breadCrumbs: [
			{ id: 1, text: 'Logistics Management', link: '/logistics-management' },
			{
				id: 2,
				text: 'Delivery Allocation',
				link: '/logistics-management/delivery-allocation',
			},
			{ id: 3, text: 'Pending Cashier Verification List', link: '' },
		],
	},
];
