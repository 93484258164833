import { startCase, toLower, uniqBy } from 'lodash';
/**
 *
 * underscore replace function
 */
export function unScReplace(str = '') {
	return startCase(toLower(str?.replace(/_/g, ' ')));
}

export function getFirstChar(str = '') {
	return str?.charAt(0);
}

export function getunique(sourceData, key) {
	return uniqBy(
		sourceData?.map((item) => ({
			text: unScReplace(item?.[key]),
			value: item?.[key],
		})),
		'value'
	);
}

export function toTitleCase(text, delimiter = ' ') {
	const splCharRegex = /[.-_]/;
	return text
		.split(splCharRegex)
		.map((word) => `${word[0].toUpperCase()}${word.substring(1, word.length)}`)
		.join(delimiter);
}
