/* eslint-disable no-else-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { Input, DatePicker, Select, Radio } from 'formik-antd';
import { isEmpty } from 'lodash';
import { message } from 'antd';
import { Box, Flex, FormInput, Text } from 'atoms';
import { disableDateRangesCheque, getTotalAmount, SelectFilterLabelOptions } from 'utils';
import { Rupee } from 'components';
import { getShortSettlementReason } from 'store/fulfilment/returnToFCSlice';

export const LabelTextInv = styled(Box)`
	font-weight: 300;
	font-size: 12px;
	line-height: 15px;
	margin-bottom: 5px;
	color: #424242;
`;

export const NewCOllected = styled(Box)`
	font-weight: 600;
	font-size: 15px;
	line-height: 15px;
`;

export const ErrorMessage = styled(Box)`
	line-height: 12px;
	position: absolute;
	font-size: 11px;
	color: #ff0000;
`;

export const ValueTextInv = styled(Box)`
	font-size: 15px;
	line-height: 28px;
	color: #000000;
`;

export const AddMore = styled(Box)`
	font-size: 12px;
	line-height: 14px;
	color: #000000;
	padding: 0 5px 0 15px;
	align-self: center;
	margin-left: auto;
	font-weight: 700;
	cursor: pointer;
`;

export const OverlayStyle = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: white
	color: white;
	z-index: 1000;
	pointer-events: none;
`;

export const InfoMessage = styled(Text)`
	color: black;
	font-size: 18px;
	font-weight: 600;
	text-shadow: rgba(0, 0, 0, 0.125) 0px 3px;
`;

export const PaymentFieldsContainer = styled(Box)`
	transition: filter 0.3s ease;
`;

const makeEmptydetails = (data, setFieldValue, index) => {
	if (data?.includes('upi')) {
		setFieldValue(`upi.${index}.ref_no`, undefined);
	}
	if (data?.includes('neft')) {
		setFieldValue(`neft.${index}.ref_no`, undefined);
	}
	if (data?.includes('cheque')) {
		setFieldValue(`cheque.${index}.ref_no`, undefined);
		setFieldValue(`cheque.${index}.bank_id`, undefined);
		setFieldValue(`cheque.${index}.cheque_due_date`, undefined);
	}
};
export default function RTCCollectionForm({
	values,
	bankList = [],
	setFieldValue,
	errors,
	data,
	setErrors,
	uploadAllowed,
	rfcEditBlock,
}) {
	const { bnpl_enabled, bnpl_status } = data?.order ?? {};
	const AmountTotal = getTotalAmount(values);
	console.log(AmountTotal);
	// const AmountTotal =
	// 	(Number(values?.cash) || 0) +
	// 	(Number(values?.cheque[0]?.amount) || 0) +
	// 	(Number(values?.neft[0]?.amount) || 0) +
	// 	(Number(values?.upi[0]?.amount) || 0);
	const dispatch = useDispatch();
	const collectionVerificationStatuses = ['VerifiedByCashier', 'PartialVerifiedByCashier'];
	const invoiceVerificationStatses = [
		'VerifiedBySegregator',
		'RejectedByCashier',
		'VerifiedByCashier',
		'PartialVerifiedByCashier',
	];
	const disableCollectionDetails =
		values?.order_status === 'CA' ||
		values?.order_status === 'DA' ||
		collectionVerificationStatuses.includes(data?.invoice?.invoice_verification_status);

	const disableInvoiceDetails =
		invoiceVerificationStatses.includes(data?.invoice?.invoice_verification_status) ||
		values?.order_status === 'CA' ||
		values?.order_status === 'DA';
	const hasChequeErrors = errors?.cheque && !Array.isArray(errors?.cheque);
	const hasUpiErrors = errors?.upi && !Array.isArray(errors?.upi);
	const hasNeftErrors = errors?.neft && !Array.isArray(errors?.neft);
	const [error, setError] = useState('');
	const [reasonsList, setReasonsList] = useState([]);

	const [checkNullPDC, SetcheckNullPDC] = useState(true);

	function checkNullPDCFUnc() {
		if (data?.metadata?.cheque_credit_period == null) {
			SetcheckNullPDC(false);
		} else {
			SetcheckNullPDC(true);
		}
	}
	useEffect(() => {
		checkNullPDCFUnc();
		dispatch(getShortSettlementReason())
			.then((res) => {
				const reasonData = res?.map((item) => ({
					value: item?.id,
					label: item?.reason,
				}));
				setReasonsList(reasonData);
			})
			.catch((e) => setError(e?.response?.data?.error));
	}, []);

	const handleChange = (val, name, index = 0) => {
		console.log(val, name, index);
		const assginValue = Number(val);
		console.log(assginValue);
		console.log('check');
		// RPC-1111 - Need to allow 100 extra on save

		// const checkDeliveryAmount = (parseFloat(val) || 0) > (values?.delivered_amount || 0);
		// if (checkDeliveryAmount || checkCashLimit) {
		// 	const errorMessage = checkDeliveryAmount
		// 		? 'Entered amount is greater than Delivered Amount 1x'
		// 		: `Entered cash is greated than the allowed cash limit`;

		// 	message.info(errorMessage);
		// 	makeEmptydetails(name, setFieldValue, index);
		// 	return setFieldValue(name, 0);
		// }

		const checkCashLimit = name === 'cash' && Number(values?.cashLimit) < (assginValue || 0);
		const isUPILimitExceeded = name && name.startsWith('upi.') && (assginValue || 0) > 100000;

		if (checkCashLimit) {
			const errorMessage = 'Entered amount is greater than Delivered Amount';

			message.info(errorMessage);
			makeEmptydetails(name, setFieldValue, index);
			return setFieldValue(name, 0);
		}

		if (isUPILimitExceeded) {
			const errorMessage = 'UPI amount can not exceed 1 lakh';

			message.info(errorMessage);
			makeEmptydetails(name, setFieldValue, index);
			return setFieldValue(name, 0);
		}

		if (assginValue === 0) {
			makeEmptydetails(name, setFieldValue, index);
		}

		return setFieldValue(name, assginValue);
	};
	const getZeroValue = (val) => {
		if (val >= 0) {
			return true;
		} else if (val < 0) {
			return true;
		} else return false;
	};
	const getValueCheck = () => {
		// console.log(values);
		// console.log('current_outstanding_amount', data?.invoice?.current_outstanding_amount);
		// console.log('current_outstanding_amount', data?.invoice);

		console.log('AmountTotal', AmountTotal);
		// console.log(
		// 	'condition',
		// 	Number(data?.invoice?.current_outstanding_amount) >= 0 &&
		// 		AmountTotal < Number(values?.delivered_amount)
		// );

		// eslint-disable-next-line sonarjs/prefer-single-boolean-return
		if (
			getZeroValue(Number(data?.invoice?.current_outstanding_amount)) &&
			AmountTotal < Number(values?.delivered_amount)
		) {
			return true;
		} else {
			return false;
		}
	};

	const quidBasedConditions =
		bnpl_enabled &&
		(bnpl_status === 'ON_CREDIT' ||
			bnpl_status === 'CONSENT_RECEIVED' ||
			bnpl_status === 'INVOICE_UPLOADED');

	console.log(quidBasedConditions, bnpl_enabled, bnpl_status, 'quidBasedConditions');

	return (
		<Box className="RTCCollectionPage" padding="20px 30px">
			<Flex marginBottom="20px">
				<Box flex="0 0 12%">
					<LabelTextInv>Invoice Value</LabelTextInv>
					<ValueTextInv>
						<Rupee roundOff={0}>{values?.invoice_amount || 0}</Rupee>
					</ValueTextInv>
				</Box>
				<Box flex="0 0 12%">
					<LabelTextInv>Collectable Value</LabelTextInv>
					<ValueTextInv>
						<Rupee roundOff={0}>{values?.delivered_amount || 0}</Rupee>
					</ValueTextInv>
				</Box>
				<Box flex="0 0 12%">
					<LabelTextInv>Collected Value</LabelTextInv>
					<ValueTextInv>
						<Rupee roundOff={0}>{values?.collected_amount || 0}</Rupee>
					</ValueTextInv>
				</Box>
				<Box flex="0 0 12%">
					<LabelTextInv>Collected date</LabelTextInv>
					<ValueTextInv>{values?.collection_date || '---'}</ValueTextInv>
				</Box>
				{console.log('rfcEditBlock', rfcEditBlock)}
				<Box>
					<LabelTextInv>Invoice Status</LabelTextInv>
					<ValueTextInv>
						<Radio.Group
							disabled={
								!uploadAllowed ||
								disableInvoiceDetails ||
								values?.return_verified_by ||
								rfcEditBlock
							}
							name="invoice_status"
							defaultValue={values?.invoice_status}
						>
							<Radio name="invoice_status" value="Bill Back">
								Invoice Returned
							</Radio>
							<Radio name="invoice_status" value="Voucher">
								Voucher
							</Radio>

							<Radio name="invoice_status" value="No Bill Back">
								Invoice settled
							</Radio>
						</Radio.Group>
						{errors?.invoice_status && (
							<ErrorMessage>{errors?.invoice_status}</ErrorMessage>
						)}
					</ValueTextInv>
				</Box>
				{console.log('getValueCheck', getValueCheck())}
				{values?.invoice_status === 'No Bill Back' && getValueCheck() && (
					<Flex style={{ flexDirection: 'column', alignItems: 'center' }}>
						<Box padding="0 5px">
							<FormInput
								name="short_settlement_reason"
								label="Short Settlement Reason"
							>
								<Select
									name="short_settlement_reason"
									showSearch
									// disabled={
									// 	disableCollectionDetails ||
									// 	!(parseFloat(values?.cheque[index].amount) > 0)
									// }
									filterOption={SelectFilterLabelOptions}
									placeholder="Select Reason"
									options={reasonsList}
									style={{
										width: '350px',
									}}
								/>
							</FormInput>
						</Box>
						{!isEmpty(error) && (
							<Text style={{ color: 'red', fontSize: '12px', marginTop: '-20px' }}>
								{error}
							</Text>
						)}
					</Flex>
				)}
			</Flex>
			<PaymentFieldsContainer>
				<div style={{ position: 'relative' }}>
					<div>
						<Flex>
							<LabelTextInv flex="0 0 14%">Mode</LabelTextInv>
							<LabelTextInv flex="0 0 15%">Details</LabelTextInv>
						</Flex>

						<Flex borderBottom="1px solid #0000001a">
							<ValueTextInv alignSelf="center" flex="0 0 12%">
								Cash:
							</ValueTextInv>
							<Box padding="0 5px">
								<FormInput name="cash" label="Amount">
									<Input
										disabled={
											!uploadAllowed ||
											disableCollectionDetails ||
											values?.return_verified_by ||
											data?.invoice?.block_level === (0 || 1) ||
											rfcEditBlock
										}
										style={{ width: '200px' }}
										onChange={({ target }) =>
											handleChange(target?.value, 'cash')
										}
										name="cash"
										type="number"
										onWheel={(e) => e.target.blur()}
										min="0"
									/>
									<Box fontSize="12px" lineHeight="20px">
										Allowed Cash limit:{' '}
										<Rupee style={{ fontWeight: 700 }}>
											{values.cashLimit}
										</Rupee>
									</Box>
								</FormInput>
							</Box>
						</Flex>
						{hasChequeErrors && (
							<ErrorMessage marginTop="8px">{errors?.cheque}</ErrorMessage>
						)}
						<Flex paddingTop="10px" borderBottom="1px solid #0000001a">
							<ValueTextInv alignSelf="center" flex="0 0 12%">
								Cheque:
							</ValueTextInv>
							<Box width="100%">
								<FieldArray
									name="cheque"
									render={(arrayHelpers) =>
										values?.cheque?.map((x, index) => (
											<Flex key={index}>
												<Box padding="0 5px">
													{checkNullPDC && (
														<FormInput
															name={`cheque.${index}.amount`}
															label="Amount"
														>
															<Input
																style={{
																	width: '200px',
																}}
																type="number"
																min="0"
																onWheel={(e) => e.target.blur()}
																onChange={({ target }) =>
																	handleChange(
																		target?.value,
																		`cheque.${index}.amount`,
																		index
																	)
																}
																disabled={
																	!uploadAllowed ||
																	disableCollectionDetails ||
																	values?.return_verified_by ||
																	data?.invoice?.block_level ===
																		(0 || 1) ||
																	rfcEditBlock
																}
																name={`cheque.${index}.amount`}
															/>
														</FormInput>
													)}
													{checkNullPDC === false && (
														<FormInput
															name={`cheque.${index}.amount`}
															label="Amount"
															className="show-pdc-error "
														>
															<Input
																style={{
																	width: '200px',
																	font: '10px',
																}}
																type="string"
																disabled
																value="Contact support team, Credit period not defined"
																name={`cheque.${index}.amount`}
															/>
														</FormInput>
													)}
												</Box>

												<Box padding="0 5px">
													<FormInput
														name={`cheque.${index}.ref_no`}
														label="Cheque Number"
													>
														<Input
															pattern="[0-9]*"
															inputMode="numeric"
															maxLength={6}
															onKeyPress={(e) => {
																if (
																	!e.nativeEvent.key?.match(
																		/[0-9]+/
																	)
																) {
																	e.nativeEvent.preventDefault();
																}
															}}
															style={{
																width: '200px',
															}}
															disabled={
																disableCollectionDetails ||
																!(
																	parseFloat(
																		values?.cheque[index].amount
																	) > 0
																) ||
																rfcEditBlock
															}
															name={`cheque.${index}.ref_no`}
														/>
													</FormInput>
												</Box>
												<Box padding="0 5px">
													<FormInput
														name={`cheque.${index}.bank_name`}
														label="Bank Name"
													>
														<Select
															name={`cheque.${index}.bank_id`}
															showSearch
															disabled={
																disableCollectionDetails ||
																!(
																	parseFloat(
																		values?.cheque[index].amount
																	) > 0
																) ||
																rfcEditBlock
															}
															filterOption={SelectFilterLabelOptions}
															placeholder="Select Bank"
															options={bankList}
															style={{
																width: '200px',
															}}
														/>
													</FormInput>
												</Box>

												<Box padding="0 5px">
													<FormInput
														name={`cheque.${index}.cheque_due_date`}
														label="Due Date"
													>
														<DatePicker
															name={`cheque.${index}.cheque_due_date`}
															format="DD/MM/YYYY"
															type="date"
															showToday={false}
															disabled={
																disableCollectionDetails ||
																!(
																	parseFloat(
																		values?.cheque[index].amount
																	) > 0
																) ||
																rfcEditBlock
															}
															style={{
																width: '200px',
															}}
															disabledDate={disableDateRangesCheque(
																values?.invoice_date,
																data?.metadata
															)}
														/>
													</FormInput>
												</Box>
												{checkNullPDC && (
													<AddMore
														onClick={
															index === 0
																? () =>
																		arrayHelpers.push({
																			amount: undefined,
																			bank_name: undefined,
																			cheque_due_date:
																				undefined,
																			ref_no: undefined,
																		})
																: () => {
																		arrayHelpers.pop(index);
																		if (hasChequeErrors)
																			setErrors({
																				...errors,
																				cheque: [],
																			});
																  }
														}
													>
														{index === 0
															? 'Add more cheque'
															: 'Remove cheque'}
													</AddMore>
												)}
											</Flex>
										))
									}
								/>
							</Box>
						</Flex>
						{hasUpiErrors && <ErrorMessage marginTop="8px">{errors?.upi}</ErrorMessage>}
						<Flex paddingTop="10px" borderBottom="1px solid #0000001a">
							<ValueTextInv alignSelf="center" flex="0 0 12%">
								UPI:
							</ValueTextInv>
							<Box width="100%">
								<FieldArray
									name="upi"
									render={(arrayHelpers) =>
										values?.upi?.map((_, index) => (
											<Flex key={index}>
												<Box padding="0 5px">
													<FormInput
														name={`upi.${index}.amount`}
														label="Amount"
													>
														<Input
															style={{
																width: '200px',
															}}
															onChange={({ target }) =>
																handleChange(
																	target?.value,
																	`upi.${index}.amount`,
																	index
																)
															}
															onWheel={(e) => e.target.blur()}
															type="number"
															min="0"
															disabled={
																!uploadAllowed ||
																disableCollectionDetails ||
																values?.return_verified_by ||
																data?.invoice?.block_level ===
																	(0 || 1) ||
																rfcEditBlock
															}
															name={`upi.${index}.amount`}
														/>
														<Box fontSize="12px" lineHeight="20px">
															Allowed UPI limit:{' '}
															<Rupee style={{ fontWeight: 700 }}>
																100000
															</Rupee>
														</Box>
													</FormInput>
												</Box>
												<Box padding="0 5px">
													<FormInput
														name={`upi.${index}.ref_no`}
														label="UPI Reference Number"
													>
														<Input
															style={{
																width: '200px',
															}}
															disabled={
																disableCollectionDetails ||
																!(
																	parseFloat(
																		values?.upi[index].amount
																	) > 0
																) ||
																rfcEditBlock
															}
															name={`upi.${index}.ref_no`}
														/>
													</FormInput>
												</Box>
												<AddMore
													onClick={
														index === 0
															? () =>
																	arrayHelpers.push({
																		amount: undefined,
																		ref_no: undefined,
																	})
															: () => {
																	arrayHelpers.pop(index);
																	if (hasUpiErrors)
																		setErrors({
																			...errors,
																			upi: [],
																		});
															  }
													}
												>
													{index === 0 ? 'Add more UPI' : 'Remove UPI'}
												</AddMore>
											</Flex>
										))
									}
								/>
							</Box>
						</Flex>
						{hasNeftErrors && (
							<ErrorMessage marginTop="8px">{errors?.neft}</ErrorMessage>
						)}
						<Flex paddingTop="10px" borderBottom="1px solid #0000001a">
							<ValueTextInv alignSelf="center" flex="0 0 12%">
								NEFT:
							</ValueTextInv>
							<Box width="100%">
								<FieldArray
									name="neft"
									render={(arrayHelpers) =>
										values?.neft?.map((_, index) => (
											<Flex key={index}>
												<Box padding="0 5px">
													<FormInput
														name={`neft.${index}.amount`}
														label="Amount"
													>
														<Input
															style={{
																width: '200px',
															}}
															onChange={({ target }) =>
																handleChange(
																	target?.value,
																	`neft.${index}.amount`,
																	index
																)
															}
															onWheel={(e) => e.target.blur()}
															type="number"
															min="0"
															disabled={
																!uploadAllowed ||
																disableCollectionDetails ||
																values?.return_verified_by ||
																data?.invoice?.block_level ===
																	(0 || 1) ||
																rfcEditBlock
															}
															name={`neft.${index}.amount`}
														/>
													</FormInput>
												</Box>
												<Box padding="0 5px">
													<FormInput
														name={`neft.${index}.ref_no`}
														label=" Reference Number"
													>
														<Input
															disabled={
																disableCollectionDetails ||
																!(
																	parseFloat(
																		values?.neft[index].amount
																	) > 0
																) ||
																rfcEditBlock
															}
															style={{
																width: '200px',
															}}
															name={`neft.${index}.ref_no`}
														/>
													</FormInput>
												</Box>

												<AddMore
													onClick={
														index === 0
															? () =>
																	arrayHelpers.push({
																		amount: undefined,
																		ref_no: undefined,
																	})
															: () => {
																	arrayHelpers.pop(index);
																	if (hasNeftErrors)
																		setErrors({
																			...errors,
																			neft: [],
																		});
															  }
													}
												>
													{index === 0 ? 'Add more NEFT' : 'Remove NEFT'}
												</AddMore>
											</Flex>
										))
									}
								/>
							</Box>
						</Flex>
					</div>
					{/* {quidBasedConditions && (
						<OverlayStyle>
							<InfoCircleOutlined />
							<InfoMessage>
								You can only update the Invoice status not payment since this
								invoice belongs to Quid.
							</InfoMessage>
						</OverlayStyle>
					)} */}
				</div>

				<Box>
					<FormInput name="remarks" label="Remarks">
						<Input.TextArea
							disabled={
								!uploadAllowed ||
								disableCollectionDetails ||
								values?.return_verified_by ||
								rfcEditBlock
							}
							name="remarks"
						/>
					</FormInput>
				</Box>
			</PaymentFieldsContainer>
			<NewCOllected>New Collected Amount: {AmountTotal}</NewCOllected>
		</Box>
	);
}

RTCCollectionForm.propTypes = {
	values: PropTypes.object,
	bankList: PropTypes.array,
	setFieldValue: PropTypes.func,
	data: PropTypes.object,
	setErrors: PropTypes.func,
	errors: PropTypes.object,
	uploadAllowed: PropTypes.bool,
	rfcEditBlock: PropTypes.bool,
};
