/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, uniqBy } from 'lodash';
import { Select, Modal } from 'antd';
import * as SVGICONS from 'utils/Svg';
import { ReportsNewModal } from 'components/modals';
import { Box, Button, Flex, AsyncSelect } from 'atoms';
import { downloadExcelsUsingUrl } from 'store/UploadSlice';
import { RCTable, LoadAsyncSelectComponent, LabelWrapper } from 'components/common';
import {
	DownloadQueries,
	handleFilterChange,
	SelectFilterLabelOptions,
	DownloadColumsNew,
	toAsysnOption,
	DOWNLOADS_CHOICE,
	DOWNLOADS_CASHIER_CHOICE,
	searchBox,
	initialPaginateConfigWithoutQ,
} from 'utils';
import { useQueryParams } from 'hooks';
import { getFcListOnLoad, getFcOnSearchList } from 'store/masters';
import { getCollectionDetailsList } from 'store/downloads/reportNewSlice';

export default function Downloads() {
	const dispatch = useDispatch();

	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);
	const [btnClickSearh, setBtnClickSearh] = useState({});

	const [paginateConfig, setpaginateConfig] = useQueryParams({ filterQuery: DownloadQueries });
	const [errormodalVisible, setErrorModalVisible] = useState({ show: false, message: '' });

	const [showModal, setShowModal] = useState(false);
	const [rowData, setRowData] = useState({});
	const { auth } = useSelector((state) => state?.auth);

	useEffect(() => {
		setFcList({ type: 'Loading' });

		dispatch(
			getFcListOnLoad({ fcIds: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [] })
		).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});
	}, [dispatch]);

	const handleChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value

		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		setpaginateConfig((prev) => ({
			...handleFilterChange(name, value?.value ? [value?.value] : [], prev),
			brands: [],
		}));
	};

	const handleDownloadExcel = (id) => {
		dispatch(downloadExcelsUsingUrl({ url: `champ/reports/${id}/download`, data: {} })).then(
			(res) => {
				if (isEmpty(res)) return;
				const elem = document.createElement('a');
				elem.setAttribute('href', res?.url);
				elem.setAttribute('download', res?.name);
				elem.click();
				elem.remove();
			}
		);
	};

	return (
		<>
			<Flex justifyContent="space-between" margin="0 0 1rem">
				<Box>
					<LabelWrapper label="Select File Types" marginspace="0px 0 3px 0">
						<Select
							style={{ width: '250px', alignSelf: 'center' }}
							allowClear
							name="report_type"
							placeholder="Select File Type"
							showSearch
							filterOption={SelectFilterLabelOptions}
							value={paginateConfig?.report_type}
							options={
								auth.user.groups[0].name === 'Cashier'
									? DOWNLOADS_CASHIER_CHOICE
									: DOWNLOADS_CHOICE
							}
							onChange={(value) =>
								setpaginateConfig((prev) =>
									handleFilterChange('report_type', value, prev)
								)
							}
						/>
					</LabelWrapper>
					<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
						<LoadAsyncSelectComponent
							loadFCs={paginateConfig?.fcs}
							setList={(val) => setBrandList(val)}
							options={fcsList}
						>
							<AsyncSelect
								style={searchBox}
								allowClear
								name="fcs"
								showSearch
								fetchOptions={getFcOnSearchList}
								transformOptions={(val) => toAsysnOption(val)}
								defaultValue={paginateConfig?.fcs}
								value={paginateConfig?.fcs}
								placeholder="Select FC(s)"
								onChange={(value, options) => {
									handleChangeSelect('fcs', value, options);
								}}
								selectOptions={[...(fcsList.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
						<Select
							name="fields"
							style={searchBox}
							allowClear
							showSearch
							label="brands"
							mode="multiple"
							value={paginateConfig?.brands}
							filterOption={SelectFilterLabelOptions}
							options={brandsList}
							onChange={(value) =>
								setpaginateConfig((prev) => ({
									...prev,
									brands: value,
									...initialPaginateConfigWithoutQ,
								}))
							}
						/>
					</LabelWrapper>
					<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
				</Box>
				<Button height="40px" type="variant" onClick={() => setShowModal(true)}>
					<Flex alignItems="center" justifyContent="center">
						<Box width={32}>
							<SVGICONS.DownloadsIcons />
						</Box>
						<Box>Download Reports</Box>
					</Flex>
				</Button>
			</Flex>
			<Box>
				<RCTable
					rowKey="id"
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
					}}
					tableDatas={[rowData, setRowData]}
					dispatchAction={getCollectionDetailsList}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={DownloadColumsNew({ handleDownloadExcel, setErrorModalVisible })}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
			{showModal && (
				<ReportsNewModal
					setpaginateConfig={setpaginateConfig}
					showModal={showModal}
					setShowModal={setShowModal}
				/>
			)}
			{errormodalVisible.show && (
				<Modal
					destroyOnClose
					title="Error Message"
					visible={errormodalVisible.show}
					onOk={() => setErrorModalVisible({ message: '', show: false })}
					onCancel={() => setErrorModalVisible({ message: '', show: false })}
					footer={null}
					width="60%"
				>
					<Box>{errormodalVisible.message}</Box>
				</Modal>
			)}
		</>
	);
}
