import { useEffect, useState } from 'react';
import { Table, Typography } from 'antd';
import { dateFormat } from 'utils';
import { Box } from 'atoms';

const columns = [
	{
		title: 'FC',
		dataIndex: 'FC_Name',
		key: 'fc_name',
	},
	{
		title: 'Brand',
		dataIndex: 'Brand_Name',
		key: 'brand',
	},
	{
		title: 'Allocation Date',
		dataIndex: 'Allocation_Date',
		key: 'Allocation_Date',
		render: (val) => dateFormat(val),
	},
	{
		title: 'Invoice Number',
		dataIndex: 'Invoice_No',
		key: 'Invoice_No',
	},
	{
		title: 'Store Name',
		dataIndex: 'Store',
		key: 'store_name',
		render: (val) => val.store_name,
	},
	{
		title: 'Order Status',
		dataIndex: 'Order_Status',
		key: 'Ord_Status',
	},

	{
		title: 'RFC Closed',
		dataIndex: 'RFC_Closed_Date',
		key: 'RFC_Closed_Date',
		render: (val) => (val === 'N/A' ? 'Pending' : dateFormat(val)),
	},
	{
		title: 'Segregator Verification',
		dataIndex: 'segregator_verification_date',
		key: 'segregator_verification_date',
		render: (val) => (val === 'N/A' ? 'Pending' : dateFormat(val)),
	},
	{
		title: 'Verified by Cashier',
		dataIndex: 'verified_by_cashier_at_date',
		key: 'verified_by_cashier_at_date',
		render: (val) => (val === 'N/A' ? 'Pending' : dateFormat(val)),
	},
];

function PendingCollectionView() {
	const [pendingCollections, setPendingCollections] = useState({});

	useEffect(() => {
		const localPendingCollections = localStorage.getItem('pendingCollections');
		if (localPendingCollections) {
			setPendingCollections(JSON.parse(localPendingCollections));
			console.log(localPendingCollections);
			localStorage.removeItem('pendingCollections');
		}
	}, []);

	return (
		<>
			<Box style={{ textAlign: 'center' }}>
				<Typography.Title level={3}>
					{pendingCollections?.values?.vehicle_no}
				</Typography.Title>
			</Box>
			<Box mb={20}>
				<Typography.Text style={{ fontSize: '1.2rem' }} strong>
					Vehicle allocation failed due to following pending actions:
				</Typography.Text>
			</Box>
			<Table
				dataSource={pendingCollections?.pendingOrders || []}
				columns={columns}
				rowKey={(record) => `${record.Invoice_No}-${record.Allocation_Date}`}
				pagination={{
					total: pendingCollections?.pendingOrders?.length,
					showSizeChanger: true,
				}}
			/>
		</>
	);
}

export default PendingCollectionView;
