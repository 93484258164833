/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState, useEffect } from 'react';
import { Modal, Col, Row, Select, Form, Space, Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SelectFilterLabelOptions, dateFormat } from 'utils';
import { CommonReportForm } from 'components/forms/report-forms';
import { Text } from 'atoms';
import { LabelWrapper } from 'components/common';
import { getDownloadReports, getReportsList } from 'store/downloads/reportNewSlice';
import { toTitleCase } from 'utils/text';

const DEFAULT_REPORT_FILTERS = {
	limitations: [],
	filters: [],
	date_filters: [],
};
export default function ReportsModal({ showModal, setShowModal, setpaginateConfig }) {
	const { auth } = useSelector((state) => state?.auth);
	const [reportType, setReportType] = useState(null);
	const [reportsList, setReportsList] = useState([]);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [submitting, setSubmitting] = useState(false);
	const [reportFilters, setReportFilters] = useState({});

	useEffect(() => {
		if (reportsList.length === 0) {
			dispatch(getReportsList()).then((resp) => {
				if (resp) {
					const selectData = resp?.data.map((item) => ({
						value: item.report_name,
						label: toTitleCase(item.report_name),
						data: item,
					}));
					setReportsList(selectData);
				}
			});
		}
	}, [dispatch, reportsList]);

	const filterReportsByUserGroup = () =>
		reportsList.filter(
			(item) => !item?.data.no_show_users.includes(auth.user.groups[0].name)
		) || [];

	function handleModalClose() {
		setReportType(null);
		setShowModal(false);
		setpaginateConfig({
			query: '',
			offset: 0,
			limit: 10,
			currentPage: 1,
		});
		form.resetFields();
	}

	const handleReportChange = (report) => {
		form.resetFields();
		setReportType(report);
		if (report) {
			const reportFilter = reportsList.find((rep) => rep.value === report);
			setReportFilters(reportFilter?.data);
		} else {
			setReportFilters(DEFAULT_REPORT_FILTERS);
		}
	};

	const handleSubmit = () => {
		if (reportType) {
			form.validateFields()
				.then(() => {
					const values = form.getFieldsValue();
					const data = {
						report_type: reportType,
						date_gte: dateFormat(values?.date_range[0]),
						date_lte: dateFormat(values?.date_range[1]),
						date_type: values.daterange__type || 'document',
						brands: values?.brands,
						fcs: [values?.fcs],
						stores: values?.stores,
						vehicle_no: values?.vehicle_no,
						only_returned_skus: values?.only_returned_sku,
					};
					if (data.filter?.brands?.length === 0) {
						delete data.filter?.brands;
					}
					if (data.filter?.fcs?.length === 0) {
						delete data.filter?.fcs;
					}
					if (data.filter?.stores?.length === 0) {
						delete data.filter?.stores;
					}
					if (data.filter?.vehicle_no === '') {
						delete data.filter?.vehicle_no;
					}

					setSubmitting(true);
					if (data) {
						dispatch(getDownloadReports(data))
							.then(() => {
								form.resetFields();
								setShowModal(false);
							})
							.then(() =>
								navigate('/new_downloads?query=&offset=0&limit=10&currentPage=2')
							)
							.finally(() => {
								setSubmitting(false);
							});
					}
				})
				.catch(() => message.error('Form Incomplete'));
		} else {
			message.error('Select a Report Type.');
		}
	};
	return (
		<Modal
			title="Download Reports"
			visible={showModal}
			footer={null}
			onCancel={handleModalClose}
			width="65%"
			maskClosable={false}
		>
			<Row justify="center">
				<Col lg={24} sm={24}>
					<div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
						<LabelWrapper
							label="Report Type"
							marginspace="0px 0 -10px 0"
							style={{ justifyContent: 'center', fontSize: '12px' }}
						>
							<Select
								showSearch
								className="report__drpdwn"
								value={reportType}
								options={filterReportsByUserGroup() || []}
								filterOption={SelectFilterLabelOptions}
								allowClear
								onChange={(e) => handleReportChange(e)}
								placeholder="Select Report Type"
							/>
						</LabelWrapper>
					</div>
				</Col>
			</Row>

			<Row gutter={24} justify="center">
				<Col
					lg={24}
					sm={24}
					style={{ textAlign: 'center', fontWeight: 'bold', margin: '20px' }}
				>
					<Text mt={3}>Report Filters</Text>
				</Col>
			</Row>
			<CommonReportForm form={form} reportConfig={reportFilters} />
			<Space style={{ width: '100%', justifyContent: 'center' }}>
				<Button type="primary" disabled={submitting} onClick={handleSubmit}>
					Request Report
				</Button>
			</Space>
		</Modal>
	);
}

ReportsModal.propTypes = {
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
	setpaginateConfig: PropTypes.func,
};
