import React, { useEffect, useState } from 'react';
import {
	Row,
	Col,
	Form,
	DatePicker,
	Input,
	Select,
	Tooltip,
	Checkbox,
	message as toast,
} from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { disableFutureDate, SelectFilterLabelOptions, toAsysnOption } from 'utils';
import { getFcOnSearchList } from 'store/masters';
import { getSaleStoreList } from 'store/fulfilment/salesOrderSlice';
import { toTitleCase } from 'utils/text';

const { RangePicker } = DatePicker;

const initialValues = {
	invoice_date__gte: undefined,
	invoice_date__lte: undefined,
	fcs: undefined,
	stores: undefined,
	vehicle_no: undefined,
	brands: undefined,
	brandsOptions: [],
	fcsHiddenOptions: [],
};

const CommonStyle = {
	width: '100%',
	alignSelf: 'center',
};

export default function CommonReports({ form, reportConfig }) {
	const { limitations, filters, date_filters } = reportConfig;
	const MAX_REPORT_PERIOD = 120;
	const dispatch = useDispatch();
	const [reportRange, setReportRange] = useState(null);
	const [storeList, setStoreList] = useState([]);
	const [fcIds, setfcIds] = useState([]);
	const [fcList, setFcList] = useState([]);
	const [selectFcBrand, setSelectedFcBrands] = useState([]);
	const [dateFilters, setDateFilters] = useState([
		{ label: 'Invoice or GRN', value: 'document_date' },
	]);
	const [isloading, setIsloading] = useState(false);
	const dateLimit =
		limitations && limitations?.date ? limitations?.date?.limit_days : MAX_REPORT_PERIOD;

	useEffect(() => {
		if (fcIds.length === 0) {
			dispatch(getFcOnSearchList({})).then((res) => setFcList(toAsysnOption(res) || []));
		}
	}, [dispatch, fcIds]);

	useEffect(() => setDateFilters(generateDateFilters()), [reportConfig]);

	const updateSubFilters = (fcid) => {
		setfcIds([fcid]);
		setIsloading(() => true);
		if (filters?.includes('brand')) {
			form.setFieldsValue({ brands: undefined, stores: undefined });
			const selectBrands = fcList.filter((item) => item.value === fcid)[0];
			setSelectedFcBrands(selectBrands.brands);
			setStoreList([]);
		}

		setIsloading(() => false);
	};

	const generateDateFilters = () => {
		const report_filter =
			date_filters?.map((item) => ({
				label: toTitleCase(item),
				value: item,
			})) || [];
		return [{ label: 'Invoice or GRN', value: 'document_date' }, ...report_filter];
	};

	const fecthStoreNames = () => {
		if (filters?.includes('stores')) {
			form.setFieldsValue({ stores: undefined });
			dispatch(getSaleStoreList({ fc_ids: [form.getFieldValue('fcs')] })).then((res) =>
				setStoreList(toAsysnOption(res) || [])
			);
		} else {
			toast.info('This Report does not have a store filter.');
		}
	};

	const renderCollapsedSelectItems = (items) => (
		<Tooltip
			styles={{ root: { pointerEvents: 'none' } }}
			title={items.map(({ label }) => label).join(' || ')}
		>
			<span>{items.length} more...</span>
		</Tooltip>
	);

	const fetchFcList = (searchStr) => {
		if (searchStr.length >= 3) {
			dispatch(getFcOnSearchList({ q: `*${searchStr}*` })).then((res) =>
				setFcList(toAsysnOption(res) || [])
			);
		}
	};

	return (
		<>
			<Form layout="vertical" initialValues={initialValues} form={form}>
				<Row gutter={32} justify="space-between">
					<Col span={8} sm={24} md={12} lg={8}>
						<Form.Item
							name="daterange__type"
							label="Date Reference"
							required={[{ required: true }]}
						>
							<Select
								showSearch
								name="daterange__type"
								placeholder="Date Range"
								options={dateFilters}
								filterOption={SelectFilterLabelOptions}
								disabled={!filters?.includes('dref')}
								defaultValue={dateFilters[0]}
							/>
						</Form.Item>
					</Col>
					<Col span={8} sm={24} md={12} lg={8}>
						<Form.Item
							name="date_range"
							label="Date Range"
							rules={[{ required: true, message: 'Select a date range' }]}
						>
							<RangePicker
								name="date_range"
								format="DD-MM-YYYY"
								allowClear
								showToday={false}
								onCalendarChange={(val) => setReportRange(val)}
								disabled={!filters?.includes('date')}
								style={CommonStyle}
								disabledDate={(current) => {
									if (disableFutureDate(current)) {
										return true;
									}
									if (!reportRange) {
										return false;
									}
									const maxDate =
										reportRange[0] &&
										current.diff(reportRange[0], 'days') > dateLimit;
									const minDate =
										reportRange[1] &&
										reportRange[1].diff(current, 'days') > dateLimit;
									return !!maxDate || !!minDate;
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={8} sm={24} md={12} lg={8}>
						<Form.Item
							name="fcs"
							label="FC(s)"
							rules={[{ required: true, message: 'select a FC' }]}
						>
							<Select
								showSearch
								name="fcs"
								placeholder="FC Name"
								options={fcList || []}
								onSelect={updateSubFilters}
								onSearch={(val) => fetchFcList(val)}
								filterOption={SelectFilterLabelOptions}
								disabled={!filters?.includes('fcs')}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={32} justify="space-between">
					<Col span={8} sm={24} md={12} lg={8}>
						<Form.Item name="brands" label="Brand(s)">
							<Select
								showSearch
								className="customDropdown"
								name="brands"
								mode="multiple"
								style={{ ...CommonStyle, minHeight: '2.5rem' }}
								placeholder="Select Brand"
								filterOption={SelectFilterLabelOptions}
								options={selectFcBrand}
								disabled={
									!filters?.includes('brand') ||
									isloading ||
									!form.getFieldValue('fcs')
								}
								maxTagCount="responsive"
								maxTagPlaceholder={(omittedValues) =>
									renderCollapsedSelectItems(omittedValues)
								}
							/>
						</Form.Item>
					</Col>
					<Col span={8} sm={24} md={12} lg={8}>
						<Form.Item name="vehicle_no" label="Vehicle No">
							<Input
								name="vehicle_no"
								disabled={!filters?.includes('vehicle_no')}
								style={CommonStyle}
							/>
						</Form.Item>
					</Col>
					<Col span={8} sm={24} md={12} lg={8}>
						<Form.Item
							name="only_returned_sku"
							label="Only Returned SKU"
							valuePropName="checked"
						>
							<Checkbox
								name="only_returned_sku"
								disabled={!filters?.includes('only_returned_skus')}
							>
								Yes
							</Checkbox>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item name="stores" label="Stores">
							<Select
								showSearch
								mode="multiple"
								name="stores"
								onFocus={fecthStoreNames}
								placeholder="Store Name(s)"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								allowClear
								options={storeList || []}
								disabled={!filters?.includes('stores')}
								maxTagCount="responsive"
								style={CommonStyle}
								maxTagPlaceholder={(omittedValues) =>
									renderCollapsedSelectItems(omittedValues)
								}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<span style={{ color: 'red', fontSize: '11px' }}>
				{`Note: Report Date Range is limited to ${dateLimit > 0 ? dateLimit : '1'} day(s)
			`}
			</span>
		</>
	);
}

CommonReports.propTypes = {
	form: PropTypes.object,
	reportConfig: PropTypes.object,
};
