import { createSlice } from '@reduxjs/toolkit';
import { message as toaster } from 'antd';
import { API } from 'api';

const OMS_ENDPOINT = process.env.REACT_APP_OMS;

const initialState = {
	userList: [],
};

export const reportSlice = createSlice({
	name: 'report',
	initialState,
	reducers: {
		setUserList: (state, { payload }) => {
			state.userList = payload;
		},
	},
});

export const downloadExcel = (response, fileName) => {
	const blob = new Blob([response], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});
	const url = URL.createObjectURL(blob);
	const element = document.createElement('a');

	element.setAttribute('href', url);
	element.setAttribute('download', fileName);
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);

	toaster.success('File downloaded successfully!');
};

export const getCollectionDetailsList =
	({
		limit = 10,
		offset = 0,
		sort_column = 'id',
		sort_direction = 'DESC',
		currentPage,
		query,
		fcs,
		brands,
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: `${OMS_ENDPOINT}api/reports/list`,
			data: {
				filter: {
					...rest,
					brands: brands?.length ? brands : undefined,
					fcs: fcs?.length ? fcs : undefined,
				},
				sort: { sort_column, sort_direction },
				page: { limit, offset },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getDownloadReports = (data) => async () => {
	const CREDENTIALS = {
		url: `${OMS_ENDPOINT}api/download_reports`,
		method: 'post',
		data,
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const getReportsList = () => async () => {
	const CREDENTIALS = {
		url: `${OMS_ENDPOINT}api/list-reports`,
		method: 'get',
		config: {
			responseType: 'blob',
		},
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const { setUserList } = reportSlice.actions;
export default reportSlice.reducer;
