import { isEmpty } from 'lodash';
import { WarningOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Tooltip } from 'antd';
import { theme } from 'styles/theme';
import { Text, Box, Flex } from 'atoms';

const downloadProgressType = [
	{ id: 'uploaded', name: 'Uploaded', color: '#FF00FF', background: '#FFE7FF' },
	{ id: 'pending', name: 'Pending', color: '#17A2B8', background: '#E2F6F6' },
	{ id: 'inprogress', name: 'In Progress', color: '#fff', background: '#4B0082' },
	{ id: 'processed', name: 'Processed', color: '#17A2B8', background: '#E2F6F6' },
	{ id: 'error', name: 'Error', color: '#FF0000', background: '#FCE4E4' },
];

export const DownloadColumsNew = ({ handleDownloadExcel, setErrorModalVisible }) => [
	{
		title: 'Download File',
		dataIndex: 'report_name',
		key: 'report_name',
		overflow: 'hidden',
		render: (report_name, record) => (
			<Flex
				style={{
					whiteSpace: 'normal',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
				overFlow="hidden"
				fontSize="12px"
				color="#000"
			>
				<Box
					cursor="pointer"
					color="#0077b6"
					onClick={() => handleDownloadExcel(record?.id)}
				>
					{report_name || ''}
				</Box>
				{record?.error_message && (
					<Flex>
						<Box fontSize="18px" padding="5px" style={{ color: theme.colors.error }}>
							<Tooltip
								title={
									record?.error_message?.length > 100
										? `${record.error_message?.slice(0, 75)}...`
										: record?.error_message
								}
							>
								<Box
									onClick={() =>
										setErrorModalVisible({
											show: true,
											message: record.error_message,
										})
									}
								>
									<WarningOutlined />
								</Box>
							</Tooltip>
						</Box>
					</Flex>
				)}
			</Flex>
		),
	},
	{
		title: 'Status',
		dataIndex: 'report_status',
		key: 'report_status',
		overflow: 'hidden',
		width: '15%',
		render: (fileStatus) => {
			const getStatusName = downloadProgressType.filter((x) => x.id === fileStatus);
			const statusName = isEmpty(getStatusName)
				? { name: 'unknown status', color: '#33b5e5', background: '#FFE7FF' }
				: getStatusName[0];

			return (
				<Flex
					style={{
						background: statusName?.background || '#FFE7FF',
						borderRadius: '6px',
						padding: '8px',
						justifyContent: 'center',
					}}
				>
					<Flex marginRight="5px">
						<Box
							as="span"
							width="10px"
							height="10px"
							borderRadius="50%"
							margin="auto"
							background={statusName?.color || '#FFE7FF'}
						/>
					</Flex>
					<Text
						style={{ whiteSpace: 'normal' }}
						overFlow="hidden"
						fontSize="12px"
						lineHeight="14px"
						color={statusName.color || '#33b5e5'}
					>
						{statusName.name}
					</Text>
				</Flex>
			);
		},
	},
	{
		title: 'FC',
		dataIndex: 'fc',
		key: 'fc',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{record?.fc
					?.map((item) => item)
					?.join(', ')
					?.toString()}
			</Text>
		),
	},
	{
		title: 'Brand',
		dataIndex: 'brand',
		key: 'brand',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{record?.brand
					?.map((item) => item)
					?.join(', ')
					?.toString()}
			</Text>
		),
	},
	{
		title: 'Report Type',
		dataIndex: 'report_type',
		key: 'report_type',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (report_type) => (
			<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
				{report_type}
			</Text>
		),
	},

	{
		title: 'Created at',
		dataIndex: 'created_at',
		key: 'created_at',
		overflow: 'hidden',
		render: (x) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{moment.utc(x).add(330, 'minute').format('DD/MM/YYYY hh:mm A')}
			</Text>
		),
	},
];
